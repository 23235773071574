@layer base {
    @font-face {
        font-family: 'Gentona';
        font-weight: 600;
        font-style: normal;
        src: url('../fonts/390D70_0_0.eot');
        src: url('../fonts/390D70_0_0.eot?#iefix') format("embedded-opentype"),
             url('../fonts/390D70_0_0.woff2') format("woff2"),
             url('../fonts/390D70_0_0.woff') format("woff"),
             url('../fonts/390D70_0_0.ttf') format("truetype");
    }
    
    @font-face {
        font-family: 'Gentona';
        font-weight: 400;
        font-style: normal;
        src: url('../fonts/390D70_1_0.eot');
        src: url('../fonts/390D70_1_0.eot?#iefix') format("embedded-opentype"),
             url('../fonts/390D70_1_0.woff2') format("woff2"),
             url('../fonts/390D70_1_0.woff') format("woff"),
             url('../fonts/390D70_1_0.ttf') format("truetype");

    }
    
    @font-face {
        font-family: 'Gentona';
        font-weight: 700;
        font-style: normal;
        src: url('../fonts/390D70_2_0.eot');
        src: url('../fonts/390D70_2_0.eot?#iefix') format("embedded-opentype"),
             url('../fonts/390D70_2_0.woff2') format("woff2"),
             url('../fonts/390D70_2_0.woff') format("woff"),
             url('../fonts/390D70_2_0.ttf') format("truetype");
    }
    
    @font-face {
        font-family: 'Gentona';
        src: local("Gentona-MediumItalic"),url(../fonts/390D70_3_0.woff2) format("woff2"),url(../fonts/390D70_3_0.woff) format("woff");
        font-weight: 400;
        font-style: italic;
        src: url('../fonts/390D70_3_0.eot');
        src: url('../fonts/390D70_3_0.eot?#iefix') format("embedded-opentype"),
             url('../fonts/390D70_3_0.woff2') format("woff2"),
             url('../fonts/390D70_3_0.woff') format("woff"),
             url('../fonts/390D70_3_0.ttf') format("truetype");
    }

}